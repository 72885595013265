<script setup lang="ts">
import { api } from '@/services/api';
import { onMounted, ref, onUnmounted, onBeforeMount, computed, watch, shallowRef, nextTick } from 'vue';
import { useUnderwritingStore } from '@/stores/underwriting';
import { usePortfolioStore } from '@/stores/portfolio';
import { useDashboardStore } from '@/stores/dashboard';
import { useActuarialStore } from '@/stores/actuarial';
import { useGlobalStore } from '@/stores/global';
import ChartFilter from './ChartFilter.vue';
import { ElMessage } from 'element-plus';
import * as am5 from '@amcharts/amcharts5/index';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5xy from '@amcharts/amcharts5/xy';
import VueSlider from 'vue-slider-component';
import moment from 'moment';
import { Expand, CaretBottom, CaretRight, Close } from '@element-plus/icons-vue';
import Calendar from 'primevue/calendar';

const actuarial_store = useActuarialStore();
const underwriting_store = useUnderwritingStore();
const portfolio_store = usePortfolioStore();
const dashboard_store = useDashboardStore();

const calendarFrom = ref(moment(portfolio_store.all_uw_dates['month'][0], 'MMM-YYYY').toDate());
const calendarTo = ref(moment(dashboard_store.report_date, 'YYYY-MM-DD').toDate());
const periodStart = ref(portfolio_store.all_uw_dates['month'].indexOf(moment(calendarFrom.value).format('MMM-YYYY')));
const periodEnd = ref(portfolio_store.all_uw_dates['month'].indexOf(moment(calendarTo.value).format('MMM-YYYY')) + 1);
const sqwelr = ref(0);
const sqwelrNew = ref(0);
const asAtDate = ref(moment(dashboard_store.report_date, 'YYYY-MM-DD').format('MMM-YYYY'));

const fit_status = ref(1);
const CurveShiftExpand = ref(false);
const PeriodRangeExpand = ref(false);
const PasimonyExpand = ref(false);
const PerturbationsExpand = ref(false);
const CurveFittingExpand = ref(true);
const rateChange = ref(0);
const curveShift = ref(0);

const is_original = ref(true);
const levels_fit = ref(null);
const credit_fit = ref(null);
const pert_fit = ref(null);

const att_apriori_from_fit = ref(null);

const perturbations_sensitivity = ref(2);
const checkAllPerturbations = ref(false);
const isIndeterminatePerturbations = ref(false);
const checkedPerturbations = ref([]);
const chartClaimsPct = ref([]);
const sqr = ref([]);
const handleCheckAllPerturbationsChange = (val: boolean) => {
  checkedPerturbations.value = val
    ? underwriting_chart_data.value.map((datapointUW) => datapointUW['uw_data.UNDERWRITING_MONTH'])
    : [];
  sqr.value = val ? underwriting_chart_data.value.map((i, datapointUW) => i) : [];
  isIndeterminatePerturbations.value = false;
  graphData.value = createGraphData();
  createUnderwritingMonth();
};
const handleCheckedPerturbationsChange = (value: string[]) => {
  const checkedCount = value.length;
  sqr.value = checkedPerturbations.value.map((x) => portfolio_store.all_uw_dates['month'].indexOf(x));

  sqr.value.sort((a, b) => a - b);

  checkedPerturbations.value = sqr.value.map((x) => portfolio_store.all_uw_dates['month'][x]);

  console.log(sqr.value);
  console.log(checkedPerturbations.value);
  checkAllPerturbations.value = checkedCount === underwriting_chart_data.value.length;
  isIndeterminatePerturbations.value = checkedCount > 0 && checkedCount < underwriting_chart_data.value.length;
  graphData.value = createGraphData();
  createUnderwritingMonth();
};

const beforeEnter = (el) => {
  el.style.height = '0';
};

const enter = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const beforeLeave = (el) => {
  el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
  el.style.height = '0';
};

const chartHeightDivider = ref(2.02);
const chart_c = 1;
function syncAxes(targetChart) {
  const targetAxis = targetChart.xAxes.getIndex(0);
  if (targetAxis._skipSync != true) {
    const start = targetAxis.get('start');
    const end = targetAxis.get('end');
    am5.array.each([underwritingChart, accidentChart], function (chart: any) {
      if (chart != targetChart) {
        const axis = chart.xAxes.getIndex(0);
        axis._skipSync = true;
        axis.setAll({
          start: start,
          end: end,
        });
        axis._skipSync = false;
      }
    });
  }
}

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function onChangeVueSlider(val) {
  console.log('1');
  if (rateChange.value != 0) {
    CurveShiftExpand.value = true;
    PasimonyExpand.value = true;
  }
  if (graphData.value && graphData.value[0] && graphData.value[1]) {
    rateChange.value = val - graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'];
    curveShift.value = rateChange.value;

    graphData.value[0][graphData.value[0].length - chart_c]['attritionalPriori'] =
      graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'] + rateChange.value;

    for (let i = graphData.value[0].length - chart_c - 1; i >= 0; i--) {
      const a =
        (graphData.value[0][i]['unshiftedAttritionalPriori'] /
          graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori']) *
        graphData.value[0][graphData.value[0].length - chart_c]['attritionalPriori'];

      graphData.value[0][i]['attritionalPriori'] = isFinite(a) ? a : null;
    }

    for (let i = graphData.value[1].length - 1; i >= 0; i--) {
      let sumproduct = 0;
      for (let k = 0; k < earning_chart_data.value[i]['earning_index'].length; k++) {
        sumproduct +=
          graphData.value[0][i - earning_chart_data.value[i]['earning_index'][k]]['attritionalPriori'] *
          graphData.value[0][i - earning_chart_data.value[i]['earning_index'][k]]['GWP'] *
          earning_chart_data.value[i]['earning_ratio'][k];
      }

      graphData.value[1][i]['attritionalPriori'] = sumproduct / graphData.value[1][i]['GWP'];
    }

    sqwelrNew.value =
      (graphData.value[1]
        .slice(periodStart.value, periodEnd.value)
        .map((x) => (x['attritionalSeasonality'] - x['attritionalPriori']) ** 2 * x['GEP'])
        .reduce((t, a) => t + a, 0) /
        graphData.value[1]
          .slice(periodStart.value, periodEnd.value)
          .map((x) => x['GEP'])
          .reduce((t, a) => t + a, 0)) **
      0.5;

    setChartDataUnderwriting();
    setChartDataAccident();
    // updateForwardlooking()

    return rateChange.value.toFixed(2) + '%';
  }
  return 'None';
}

onMounted(() => {
  underwriting_store.resetFilters();
  fetchData();
});

const windowHeight = ref(0);
onBeforeMount(() => {
  windowHeight.value = window.innerHeight - 190;
});
const yAxisHeight = ref(0);

const processFunc = (dotsPos) => [[dotsPos[0], dotsPos[1], { backgroundColor: 'gray' }]];

const sliderValue = ref(0);

onUnmounted(() => {
  if (accidentRoot) {
    accidentRoot.dispose();
  }

  if (underwritingRoot) {
    underwritingRoot.dispose();
  }

  accidentRoot = null;
  underwritingRoot = null;
  underwritingChart = null;
  accidentChart = null;
  yAxis2Underwriting = null;
  yAxis2Accident = null;
  seriesAttritionalLRAccident = null;
  seriesAttritionalLRUnderwriting = null;
  seriesAttritionalAccident = null;
  seriesAttritionalUnderwriting = null;
  seriesOrigAttririonalAccident = null;
  seriesOrigAttririonalUnderwriting = null;
  xAxisAccident = null;
  xAxisUnderwriting = null;
  rangeUnderwriting = null;
  range2Underwriting = null;
  rangeAccident = null;
  range2Accident = null;
  seriesGWPUnderwriting = null;
  seriesGWPAccident = null;
  seriesGEPUnderwriting = null;
  seriesGEPAccident = null;
});

const global_store = useGlobalStore();

function openErrorMsg(msg: string) {
  ElMessage.warning(msg);
}

let yAxis2Underwriting: any = null;
let yAxis2Accident: any = null;

let seriesAttritionalLRAccident: any = null;
let seriesAttritionalLRUnderwriting: any = null;

let seriesAttritionalAccident: any = null;
let seriesAttritionalUnderwriting: any = null;

let seriesOrigAttririonalAccident: any = null;
let seriesOrigAttririonalUnderwriting: any = null;

let xAxisAccident: any = null;
let xAxisUnderwriting: any = null;

let rangeUnderwriting: any = null;
let range2Underwriting: any = null;
let rangeAccident: any = null;
let range2Accident: any = null;

let seriesGWPUnderwriting: any = null;
let seriesGWPAccident: any = null;

let seriesGEPUnderwriting: any = null;
let seriesGEPAccident: any = null;

function setChartDataAccident() {
  const data = graphData.value[1];
  xAxisAccident.data.setAll(data);
  seriesGWPAccident.set('keepSelection', true);
  seriesGWPAccident.data.setAll(data);
  seriesGEPAccident.set('keepSelection', true);
  seriesGEPAccident.data.setAll(data);
  seriesAttritionalLRAccident.set('keepSelection', true);
  seriesAttritionalLRAccident.data.setAll(data);
  seriesAttritionalAccident.set('keepSelection', true);
  seriesAttritionalAccident.data.setAll(data);
  seriesOrigAttririonalAccident.set('keepSelection', true);
  seriesOrigAttririonalAccident.data.setAll(data);
}

function setChartDataUnderwriting() {
  const data = graphData.value[0];
  xAxisUnderwriting.data.setAll(data);
  seriesGWPUnderwriting.data.setAll(data);
  seriesGEPUnderwriting.data.setAll(data);
  seriesAttritionalLRUnderwriting.data.setAll(data);
  seriesAttritionalUnderwriting.data.setAll(data);
  seriesOrigAttririonalUnderwriting.data.setAll(data);
}

let underwritingChart: any = null;
let accidentChart: any = null;

async function createUnderwritingMonth() {
  const data = graphData.value[0];

  if (underwritingRoot) {
    // If chart is already created just update the existing data
    return setChartDataUnderwriting();
  }

  underwritingRoot = am5.Root.new('underwritingDiv');
  underwritingRoot.setThemes([am5themes_Animated.new(underwritingRoot)]);
  underwritingChart = underwritingRoot.container.children.push(am5xy.XYChart.new(underwritingRoot, {}));
  let chart = underwritingChart;
  xAxisUnderwriting = chart.xAxes.push(
    am5xy.CategoryAxis.new(underwritingRoot, {
      categoryField: 'date',
      renderer: am5xy.AxisRendererX.new(underwritingRoot, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      }),
    })
  );

  xAxisUnderwriting.get('renderer').labels.template.setAll({
    rotation: -90, // Rotates the labels to 0 degrees (horizontal)
    centerY: am5.p50, // Aligns vertically to center
    centerX: am5.p50, // Aligns horizontally to center
  });

  // Create Y-axis
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(underwritingRoot, {
      renderer: am5xy.AxisRendererY.new(underwritingRoot, {
        opposite: true,
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: '#.0a',
    })
  );

  yAxis.get('renderer').grid.template.set('forceHidden', true);

  // Add additional Y-axis
  yAxis2Underwriting = chart.yAxes.push(
    am5xy.ValueAxis.new(underwritingRoot, {
      renderer: am5xy.AxisRendererY.new(underwritingRoot, {
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: "#'%'",
      min: 0,
    })
  );

  // Create series
  seriesGWPUnderwriting = chart.series.push(
    am5xy.ColumnSeries.new(underwritingRoot, {
      name: 'Unearned GWP',
      xAxis: xAxisUnderwriting,
      yAxis: yAxis,
      valueYField: 'UEP',
      categoryXField: 'date',
      fill: am5.color(0xb5e8d5),
      stroke: am5.color(0xb5e8d5),
      clustered: false,
      // ...additional configurations
    })
  );

  seriesGEPUnderwriting = chart.series.push(
    am5xy.ColumnSeries.new(underwritingRoot, {
      name: 'GEP',
      xAxis: xAxisUnderwriting,
      yAxis: yAxis,
      valueYField: 'GEP',
      categoryXField: 'date',
      fill: am5.color(0x55b691),
      stroke: am5.color(0xb5e8d5),
      clustered: false,
      // ...additional configurations
    })
  );

  // Seasonality series
  seriesAttritionalUnderwriting = chart.series.push(
    am5xy.LineSeries.new(underwritingRoot, {
      name: 'Attritional A-priori',
      valueYField: 'attritionalPriori',
      categoryXField: 'date',
      xAxis: xAxisUnderwriting,
      yAxis: yAxis2Underwriting,
      stroke: am5.color(0xeb4034),
      fill: am5.color(0xffffff),
      tooltip: am5.Tooltip.new(underwritingRoot, {}),
      strokeWidth: 2,
      snapTooltip: true,
      clustered: true,
    })
  );

  seriesAttritionalUnderwriting.strokes.template.setAll({
    strokeWidth: 3,
  });

  // Seasonality series
  seriesOrigAttririonalUnderwriting = chart.series.push(
    am5xy.LineSeries.new(underwritingRoot, {
      name: 'Original Attritional A-priori',
      valueYField: 'oriAttritionalPriori',
      categoryXField: 'date',
      xAxis: xAxisUnderwriting,
      yAxis: yAxis2Underwriting,
      stroke: am5.color(0xeb4034),
    })
  );

  seriesOrigAttririonalUnderwriting.strokes.template.setAll({
    strokeDasharray: [10, 5],
    strokeWidth: 2,
  });

  // // SeasonalityLR series
  seriesAttritionalLRUnderwriting = chart.series.push(
    am5xy.LineSeries.new(underwritingRoot, {
      name: 'Seasonality Adjusted Attritional LR',
      valueYField: 'attritionalSeasonality',
      categoryXField: 'date',
      xAxis: xAxisUnderwriting,
      yAxis: yAxis2Underwriting,
      stroke: am5.color(0x534fc6),
      fill: am5.color(0x534fc6),
      strokeWidth: 2,
    })
  );

  seriesAttritionalLRUnderwriting.strokes.template.setAll({
    strokeWidth: 3,
  });

  seriesAttritionalLRUnderwriting.data.setAll(data);

  seriesAttritionalLRUnderwriting.bullets.push(function () {
    return am5.Bullet.new(underwritingRoot, {
      sprite: am5.Circle.new(underwritingRoot, {
        strokeWidth: 2,
        templateField: 'bulletSettings',
        stroke: am5.color(0x534fc6),
      }),
    });
  });

  seriesAttritionalUnderwriting.get('tooltip').label.adapters.add('text', function (text, target) {
    let customText = '{categoryX}[/]\n';

    chart.series.each(function (item) {
      if (!item.isHidden()) {
        if (item.get('name') != 'Pertubation' && item.get('name') != 'Original attritional a-priori') {
          if (item.get('name') == 'GEP' || item.get('name') == 'Unearned GWP') {
            customText +=
              '[' +
              item.get('stroke').toString() +
              ']●[/] ' +
              item.get('name') +
              ': [b]{' +
              item.get('valueYField') +
              ".formatNumber('#.0a')}[/]\n";
          } else {
            customText +=
              '[' +
              item.get('stroke').toString() +
              ']●[/] ' +
              item.get('name') +
              ': [b]{' +
              item.get('valueYField') +
              ".formatNumber('#.0')}[/]%\n";
          }
        }
      }
    });
    return customText;
  });

  chart.set('exporting', am5plugins_exporting.Exporting.new(underwritingRoot, {}));

  // // Cursor
  chart.set(
    'cursor',
    am5xy.XYCursor.new(underwritingRoot, {
      xAxis: xAxisUnderwriting,
      yAxis: yAxis,
      yAxis2: yAxis2Underwriting,
    })
  );

  // Disabling the zoom-out button
  chart.set(
    'zoomOutButton',
    am5.Button.new(underwritingRoot, {
      disabled: true,
    })
  );
  setChartDataUnderwriting();
}

function periodChange() {
  periodStart.value = portfolio_store.all_uw_dates['month'].indexOf(moment(calendarFrom.value).format('MMM-YYYY'));
  periodEnd.value = portfolio_store.all_uw_dates['month'].indexOf(moment(calendarTo.value).format('MMM-YYYY')) + 1;

  sqwelr.value =
    (graphData.value[1]
      .slice(periodStart.value, periodEnd.value)
      .map((x) => (x['attritionalSeasonality'] - x['oriAttritionalPriori']) ** 2 * x['GEP'])
      .reduce((t, a) => t + a, 0) /
      graphData.value[1]
        .slice(periodStart.value, periodEnd.value)
        .map((x) => x['GEP'])
        .reduce((t, a) => t + a, 0)) **
    0.5;

  sqwelrNew.value =
    (graphData.value[1]
      .slice(periodStart.value, periodEnd.value)
      .map((x) => (x['attritionalSeasonality'] - x['attritionalPriori']) ** 2 * x['GEP'])
      .reduce((t, a) => t + a, 0) /
      graphData.value[1]
        .slice(periodStart.value, periodEnd.value)
        .map((x) => x['GEP'])
        .reduce((t, a) => t + a, 0)) **
    0.5;

  if (rangeUnderwriting) {
    xAxisUnderwriting.axisRanges.removeValue(rangeUnderwriting);
    xAxisAccident.axisRanges.removeValue(rangeAccident);
  }

  if (range2Underwriting) {
    xAxisUnderwriting.axisRanges.removeValue(range2Underwriting);
    xAxisAccident.axisRanges.removeValue(range2Accident);
  }

  if (
    moment(calendarFrom.value).format('MMM-YYYY') != moment(portfolio_store.all_uw_dates['month'][0]).format('MMM-YYYY')
  ) {
    let rangeDataItemUnderwriting = xAxisUnderwriting.makeDataItem({
      endCategory: moment(calendarFrom.value).subtract(1, 'months').format('MMM-YYYY'),
    });
    let rangeDataItemAccident = xAxisAccident.makeDataItem({
      endCategory: moment(calendarFrom.value).subtract(1, 'months').format('MMM-YYYY'),
    });

    rangeUnderwriting = xAxisUnderwriting.createAxisRange(rangeDataItemUnderwriting);
    rangeAccident = xAxisAccident.createAxisRange(rangeDataItemAccident);

    rangeDataItemUnderwriting.get('grid').set('forceHidden', true);
    rangeDataItemAccident.get('grid').set('forceHidden', true);

    rangeDataItemUnderwriting.get('axisFill').setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.2,
      visible: true,
    });
    rangeDataItemAccident.get('axisFill').setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.2,
      visible: true,
    });
  }

  if (calendarTo.value < moment(dashboard_store.report_date, 'YYYY-MM-DD').toDate()) {
    let rangeDataItemUnderwriting2 = xAxisUnderwriting.makeDataItem({
      category: moment(calendarTo.value).add(1, 'months').format('MMM-YYYY'),
      endCategory: moment(
        portfolio_store.all_uw_dates['month'][portfolio_store.all_uw_dates['month'].length - 1]
      ).format('MMM-YYYY'),
    });
    let rangeDataItemAccident2 = xAxisAccident.makeDataItem({
      category: moment(calendarTo.value).add(1, 'months').format('MMM-YYYY'),
      endCategory: moment(
        portfolio_store.all_uw_dates['month'][portfolio_store.all_uw_dates['month'].length - 1]
      ).format('MMM-YYYY'),
    });

    range2Underwriting = xAxisUnderwriting.createAxisRange(rangeDataItemUnderwriting2);
    range2Accident = xAxisAccident.createAxisRange(rangeDataItemAccident2);

    rangeDataItemUnderwriting2.get('grid').setAll({
      stroke: am5.color(0x00ff33),
      strokeOpacity: 0.5,
      strokeDasharray: [3],
    });

    rangeDataItemAccident2.get('grid').setAll({
      stroke: am5.color(0x00ff33),
      strokeOpacity: 0.5,
      strokeDasharray: [3],
    });

    rangeDataItemUnderwriting2.get('axisFill').setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.2,
      visible: true,
    });
    rangeDataItemAccident2.get('axisFill').setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.2,
      visible: true,
    });

    rangeDataItemUnderwriting2.get('label').set('forceHidden', true);
    rangeDataItemAccident2.get('label').set('forceHidden', true);
  }
}
function crossMultiplyAndSum(lists: number[][]): number {
  let sum = 0;

  // Helper function to recursively generate products of elements
  function recursiveMultiply(index: number, product: number) {
    if (index === lists.length) {
      sum += product;
      return;
    }

    for (let i = 0; i < lists[index].length; i++) {
      recursiveMultiply(index + 1, product * lists[index][i]);
    }
  }

  // Start the recursive multiplication
  recursiveMultiply(0, 1);

  return sum;
}
function createGraphData() {
  let dataUW: any = [];
  let dataACC: any = [];
  const current_date = moment(dashboard_store.report_date, 'YYYY-MM-DD');
  console.log(portfolio_store.parameters.actuarial_grouping);
  console.log(underwriting_chart_data.value);
  for (const i in underwriting_chart_data.value) {
    const datapointUW = underwriting_chart_data.value[i];
    const datapointACC = accident_chart_data.value[i];
    let date = moment(datapointUW['uw_data.UNDERWRITING_MONTH'], 'MMM-YYYY');

    let totalPercentage = 0;
    let claimsSelectedPct = [];
    for (const claimsIdx in portfolio_store.parameters.actuarial_grouping) {
      const claims = portfolio_store.parameters.actuarial_grouping[claimsIdx];
      const claimsSelections = underwriting_store.convertedFilter[claims];

      let tempSelectedClaimsPct = [];

      for (const claims of claimsSelections) {
        tempSelectedClaimsPct.push(chartClaimsPct.value[claimsIdx][parseInt(claims)]);
      }
      claimsSelectedPct.push(tempSelectedClaimsPct);
    }

    totalPercentage = parseFloat(crossMultiplyAndSum(claimsSelectedPct).toFixed(2));

    const modelPctUCC =
      datapointUW[
        'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
      ] / datapointUW['uw_data.GEP_AMOUNT'];

    let temp_uw = {
      date: datapointUW['uw_data.UNDERWRITING_MONTH'],
      GWP: datapointUW['uw_data.GEP_AMOUNT'],
      GEP: datapointUW['GEP'],
      UEP: datapointUW['uw_data.GEP_AMOUNT'] - datapointUW['GEP'],
      attritionalPriori:
        (datapointUW[
          'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
        ] /
          datapointUW['uw_data.GEP_AMOUNT']) *
        100,
      unshiftedAttritionalPriori:
        (att_apriori_from_fit.value
          ? att_apriori_from_fit.value[i]
          : datapointUW[
              'uw_data.' +
                portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] +
                '_MODEL'
            ] / datapointUW['uw_data.GEP_AMOUNT']) * 100,
      oriAttritionalPriori:
        (datapointUW[
          'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
        ] /
          datapointUW['uw_data.GEP_AMOUNT']) *
        100,
      attritionalSeasonality:
        date <= current_date
          ? ((datapointUW[
              'claims_data.' +
                portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] +
                '_inc'
            ] +
              datapointUW[
                portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_ibnr'
              ]) /
              datapointUW[
                'uw_data.' +
                  portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] +
                  '_seasonality'
              ] /
              datapointUW['GEP'] +
              modelPctUCC * (checkIfAllHierarchiesIsChecked() ? 0 : totalPercentage)) *
            100
          : null,
      bulletSettings: checkedPerturbations.value.includes(datapointUW['uw_data.UNDERWRITING_MONTH'])
        ? { fill: am5.color('#ff0000'), radius: 5 }
        : { fill: am5.color('#ffffff'), radius: 3 },
    };

    dataUW.push(temp_uw);
    date = moment(datapointACC['uw_data.ACCIDENT_MONTH'], 'MMM-YYYY');
    const modelPctACC =
      datapointACC[
        'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
      ] / datapointACC['uw_data.GEP_AMOUNT'];

    let temp_acc = {
      date: datapointACC['uw_data.ACCIDENT_MONTH'],
      GWP: datapointACC['uw_data.GEP_AMOUNT'],
      GEP: datapointACC['GEP'],
      UEP: datapointACC['uw_data.GEP_AMOUNT'] - datapointACC['GEP'],
      attritionalPriori:
        (datapointACC[
          'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
        ] /
          datapointACC['uw_data.GEP_AMOUNT']) *
        100,
      oriAttritionalPriori:
        (datapointACC[
          'uw_data.' + portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_MODEL'
        ] /
          datapointACC['uw_data.GEP_AMOUNT']) *
        100,
      attritionalSeasonality:
        date <= current_date
          ? ((datapointACC[
              'claims_data.' +
                portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] +
                '_inc'
            ] +
              datapointACC[
                portfolio_store.parameters['claims_nature'][underwriting_store.chart_claims_selection] + '_ibnr'
              ]) /
              datapointACC['uw_data.ATTRITIONAL_seasonality'] /
              datapointACC['uw_data.GEP_AMOUNT'] +
              modelPctACC * (checkIfAllHierarchiesIsChecked() ? 0 : totalPercentage)) *
            100
          : null,
    };

    dataACC.push(temp_acc);
  }

  sqwelr.value =
    (dataACC
      .map((x) => (x['attritionalSeasonality'] - x['attritionalPriori']) ** 2 * x['GEP'])
      .reduce((t, a) => t + a, 0) /
      dataACC.map((x) => x['GEP']).reduce((t, a) => t + a, 0)) **
    0.5;

  return [dataUW, dataACC];
}

function plotGraph() {
  // const data = createGraphData()
  createUnderwritingMonth();
  createAccidentMonth();
  sliderValue.value = graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'];
}

let underwritingRoot: any = null;
let accidentRoot: any = null;

async function createAccidentMonth() {
  const data = graphData.value[1];
  if (accidentRoot) {
    return setChartDataAccident();
  }

  console.log(data);

  accidentRoot = am5.Root.new('accidentDiv');
  accidentRoot.setThemes([am5themes_Animated.new(accidentRoot)]);

  accidentChart = accidentRoot.container.children.push(am5xy.XYChart.new(accidentRoot, {}));
  let chart = accidentChart;
  xAxisAccident = chart.xAxes.push(
    am5xy.CategoryAxis.new(accidentRoot, {
      categoryField: 'date',
      renderer: am5xy.AxisRendererX.new(accidentRoot, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      }),
    })
  );

  xAxisAccident.data.setAll(data);

  xAxisAccident.get('renderer').labels.template.setAll({
    rotation: -90, // Rotates the labels to 0 degrees (horizontal)
    centerY: am5.p50, // Aligns vertically to center
    centerX: am5.p50, // Aligns horizontally to center
  });

  // Create Y-axis
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(accidentRoot, {
      renderer: am5xy.AxisRendererY.new(accidentRoot, {
        opposite: true,
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: '#.0a',
    })
  );

  yAxis.get('renderer').grid.template.set('forceHidden', true);
  // Add additional Y-axis
  yAxis2Accident = chart.yAxes.push(
    am5xy.ValueAxis.new(accidentRoot, {
      renderer: am5xy.AxisRendererY.new(accidentRoot, {
        cellStartLocation: 0,
        minGridDistance: 30,
      }),
      numberFormat: "#'%'",
      min: 0,
    })
  );

  // Create series
  seriesGWPAccident = chart.series.push(
    am5xy.ColumnSeries.new(accidentRoot, {
      name: 'Unearned GWP',
      xAxis: xAxisAccident,
      yAxis: yAxis,
      valueYField: 'UEP',
      categoryXField: 'date',
      fill: am5.color(0xb5e8d5),
      stroke: am5.color(0xb5e8d5),
      clustered: false,
      // ...additional configurations
    })
  );

  seriesGEPAccident = chart.series.push(
    am5xy.ColumnSeries.new(accidentRoot, {
      name: 'GEP',
      xAxis: xAxisAccident,
      yAxis: yAxis,
      valueYField: 'GEP',
      categoryXField: 'date',
      fill: am5.color(0x55b691),
      stroke: am5.color(0xb5e8d5),
      clustered: false,
      // ...additional configurations
    })
  );

  // Seasonality series
  seriesAttritionalAccident = chart.series.push(
    am5xy.LineSeries.new(accidentRoot, {
      name: 'Attritional A-priori',
      valueYField: 'attritionalPriori',
      categoryXField: 'date',
      xAxis: xAxisAccident,
      yAxis: yAxis2Accident,
      stroke: am5.color(0xeb4034),
      fill: am5.color(0xffffff),
      tooltip: am5.Tooltip.new(accidentRoot, {}),
      strokeWidth: 2,
      snapTooltip: true,
      clustered: true,
    })
  );

  seriesAttritionalAccident.strokes.template.setAll({
    strokeWidth: 3,
  });

  // Seasonality series
  seriesOrigAttririonalAccident = chart.series.push(
    am5xy.LineSeries.new(accidentRoot, {
      name: 'Original Attritional A-priori',
      valueYField: 'oriAttritionalPriori',
      categoryXField: 'date',
      xAxis: xAxisAccident,
      yAxis: yAxis2Accident,
      stroke: am5.color(0xeb4034),
      strokeWidth: 2,
    })
  );

  seriesOrigAttririonalAccident.strokes.template.setAll({
    strokeDasharray: [10, 5],
    strokeWidth: 2,
  });

  // // SeasonalityLR series
  seriesAttritionalLRAccident = chart.series.push(
    am5xy.LineSeries.new(accidentRoot, {
      name: 'Seasonality Adjusted Attritional LR',
      valueYField: 'attritionalSeasonality',
      categoryXField: 'date',
      xAxis: xAxisAccident,
      yAxis: yAxis2Accident,
      stroke: am5.color(0x534fc6),
      fill: am5.color(0x534fc6),
      strokeWidth: 2,
    })
  );

  seriesAttritionalLRAccident.strokes.template.setAll({
    strokeWidth: 3,
  });

  seriesAttritionalLRAccident.bullets.push(function () {
    return am5.Bullet.new(accidentRoot, {
      sprite: am5.Circle.new(accidentRoot, {
        radius: 3,
        strokeWidth: 2,
        fill: am5.color(0xffffff),
        stroke: am5.color(0x534fc6),
      }),
    });
  });

  seriesAttritionalAccident.get('tooltip').label.adapters.add('text', function (text, target) {
    let customText = '{categoryX}[/]\n';

    chart.series.each(function (item) {
      if (!item.isHidden()) {
        if (item.get('name') != 'Pertubation' && item.get('name') != 'Original attritional a-priori') {
          if (item.get('name') == 'GEP' || item.get('name') == 'Unearned GWP') {
            customText +=
              '[' +
              item.get('stroke').toString() +
              ']●[/] ' +
              item.get('name') +
              ': [b]{' +
              item.get('valueYField') +
              ".formatNumber('#.0a')}[/]\n";
          } else {
            customText +=
              '[' +
              item.get('stroke').toString() +
              ']●[/] ' +
              item.get('name') +
              ': [b]{' +
              item.get('valueYField') +
              ".formatNumber('#.0')}[/]%\n";
          }
        }
      }
    });
    return customText;
  });

  chart.set('exporting', am5plugins_exporting.Exporting.new(accidentRoot, {}));

  // // Cursor
  chart.set(
    'cursor',
    am5xy.XYCursor.new(accidentRoot, {
      xAxis: xAxisAccident,
      yAxis: yAxis,
      yAxis2: yAxis2Accident,
    })
  );

  // Disabling the zoom-out button
  chart.set(
    'zoomOutButton',
    am5.Button.new(accidentRoot, {
      disabled: true,
    })
  );

  let scrollbarX = am5.Scrollbar.new(accidentRoot, {
    orientation: 'horizontal',
  });

  let scrollbarY = am5.Scrollbar.new(accidentRoot, {
    orientation: 'vertical',
  });

  chart.set('scrollbarX', scrollbarX);
  chart.bottomAxesContainer.children.push(scrollbarX);

  yAxisHeight.value = yAxis2Accident.height() - 90;

  xAxisAccident.on('start', function () {
    syncAxes(chart);
  });
  xAxisAccident.on('end', function () {
    syncAxes(chart);
  });
}

const underwriting_chart_data = ref([]);
const current_month_index = ref(null);
const current_month_data = ref(null);
const next_12_months_data = ref(null);
const after_12_months_data = ref(null);
const accident_chart_data = ref([]);
const earning_chart_data = ref([]);
const data_points = ref(0);
const levels = ref(0);
const rates = ref(0);

const forwardlooking = ref([0, 0, 0]);

function checkIfAllHierarchiesIsChecked() {
  return portfolio_store.parameters['actuarial_grouping'].every((group) => {
    return Object.keys(portfolio_store.dictionary[group]).length == underwriting_store.convertedFilter[group].length;
  });
}

async function fetchData() {
  global_store.setLoading(true);

  let hierarchies_order: any = [];
  for (const i of underwriting_store.hierarchyOrder) {
    hierarchies_order.push(portfolio_store.parameters['hierarchies'][i]);
  }

  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/graph-data', {
      bounce_id: portfolio_store.selectedBounceID,
      filters_hierarchy: underwriting_store.convertedFilter,
      report_date: dashboard_store.report_date,
      parameters_json: portfolio_store.parameters,
      hierarchies_order: hierarchies_order.slice(0, underwriting_store.currentLevel),
      claim_category: 0,
      large_method: portfolio_store.parameters['large_method'],
      large_threshold: portfolio_store.parameters['large_threshold'],
    })
    .then((res) => {
      underwriting_chart_data.value = JSON.parse(res.data.data['underwriting']);
      accident_chart_data.value = JSON.parse(res.data.data['accident']);
      earning_chart_data.value = JSON.parse(res.data.data['earning']);
      console.log(underwriting_chart_data.value);
      console.log(accident_chart_data.value);
      chartClaimsPct.value = res.data.data['raw_claims_pct'][0];
      // chartClaimsPct.value = JSON.parse(res.data.data['parameters'])[0]['CLAIMS_COMBINATION_PC'][0];
      console.log(chartClaimsPct.value);

      data_points.value = res.data.data['data_points'];
      levels.value = res.data.data['levels'];
      rates.value = res.data.data['rates'];

      current_month_index.value = underwriting_chart_data.value
        .map((x) => x['uw_data.UNDERWRITING_MONTH'])
        .indexOf(asAtDate.value);
      current_month_data.value = underwriting_chart_data.value[current_month_index.value];
      next_12_months_data.value = underwriting_chart_data.value.slice(
        current_month_index.value + 1,
        current_month_index.value + 13
      );
      after_12_months_data.value = underwriting_chart_data.value[current_month_index.value + 12];

      graphData.value = createGraphData();
      plotGraph();
      global_store.setLoading(false);
    })
    .catch((e) => {
      console.log(e);
      global_store.setLoading(false);
      openErrorMsg('Error!');
    });
}

const graphData = ref(null);

function resizeFilter() {
  const numericValue = parseInt(filtersWidth.value, 10); // Extract numeric value from the string
  filtersWidth.value = `${350 - numericValue}px`; // Calculate and append 'px' back
}

const filtersWidth = ref('300px');

function changeCurveShift() {
  sliderValue.value =
    graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'] + curveShift.value;
  // onChangeVueSlider(curveShift.value + sliderValue.value)
}
function resetCurveShift() {
  sliderValue.value = graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'];
  curveShift.value = 0;
  // onChangeVueSlider(sliderValue.value)
}

const filtersKeys = computed(() => {
  return underwriting_store.filtersKeys;
});

watch(filtersKeys, () => {
  resetFit();
});

function resetFit() {
  att_apriori_from_fit.value = null;
  levels_fit.value = null;
  credit_fit.value = null;
  pert_fit.value = null;

  is_original.value = true;

  fitting_default_run(0);
}

function fitting_default_run(s) {
  curveShift.value = s;
  rateChange.value = s;

  graphData.value = createGraphData();

  createUnderwritingMonth();
  createAccidentMonth();

  sliderValue.value = graphData.value[0][graphData.value[0].length - chart_c]['unshiftedAttritionalPriori'] + s;
}

async function PertLocate() {
  global_store.setLoading(true);
  fit_status.value = 0;
  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/pert-locate', {
      bounce_id: portfolio_store.selectedBounceID,
      filters_hierarchy: underwriting_store.convertedFilter,
      report_date: dashboard_store.report_date,
      parameters_json: portfolio_store.parameters,
      claim_category: 0,
      large_method: portfolio_store.parameters['large_method'],
      large_threshold: portfolio_store.parameters['large_threshold'],
      sensitivity: perturbations_sensitivity.value / 100,
    })
    .then((res) => {
      sqr.value = res.data.data['sqr'].filter(
        (x) => x < underwriting_chart_data.value.map((x) => x['uw_data.UNDERWRITING_MONTH']).indexOf(asAtDate.value) - 9
      );
      checkedPerturbations.value = res.data.data['perturbations'].slice(0, sqr.value.length);
      // checkedPerturbations.value = res.data.data['perturbations']
      // sqr.value = res.data.data['sqr']
      handleCheckedPerturbationsChange(checkedPerturbations.value);

      global_store.setLoading(false);
      fit_status.value = 1;
    })
    .catch((e) => {
      console.log(e);
      global_store.setLoading(false);
      fit_status.value = 1;
      openErrorMsg('No perturbation points could be defined on the selected data');
    });
}

async function confirmFitData() {
  console.log(sqr.value);
  console.log(portfolio_store.all_uw_dates);
  global_store.setLoading(true);
  await api
    .post(import.meta.env.VITE_API_URL + 'underwriting/set-curve-fit', {
      bounce_id: portfolio_store.selectedBounceID,
      filters: underwriting_store.convertedFilter,
      commission: portfolio_store.parameters['Commission'],
      claims_nature: portfolio_store.parameters['claims_nature'],
      first_month: moment(portfolio_store.all_uw_dates['month'][0], 'MMM-YYYY').format('YYYY-MM-DD'),
      line_size: Object.values(portfolio_store.parameters['line_size']),
      selectedLineSize: portfolio_store.selectedLineSize,

      perts_location: sqr.value,
      curve_shift: curveShift.value,

      starting_points: levels_fit.value,
      credits: credit_fit.value,
      perts: pert_fit.value,
    })
    .then(async (res) => {
      actuarial_store.createDraft(res);
      portfolio_store.getSignedOff();
      portfolio_store.onResetForwardLooking(true);
      actuarial_store.setActuarialCacheResetRequired(true);
      try {
        await actuarial_store.resetActuarialCache();
      } catch (e) {
        openErrorMsg('Error calculating IBNR!');
      }
      await fetchData().then(() => {
        resetFit();
        global_store.setLoading(false);
      });
    })
    .catch((e) => {
      openErrorMsg('Error!');
      global_store.setLoading(false);
      portfolio_store.getSignedOff();
      console.log(e);
    });
}

async function fitData(fitType: string) {
  global_store.setLoading(true);
  fit_status.value = 0;
  let fit_type_list;
  if (fitType == 'all') {
    fit_type_list = ['perturbations', 'internal', 'level'];
  } else {
    fit_type_list = [fitType];
  }

  if (checkedPerturbations.value.length == 0) {
    fit_type_list = fit_type_list.filter((item) => item !== 'perturbations');
  }

  if (rates.value == 0) {
    fit_type_list = fit_type_list.filter((item) => item !== 'internal');
  }

  let hierarchies_order: any = [];
  for (const i of underwriting_store.hierarchyOrder) {
    hierarchies_order.push(portfolio_store.parameters['hierarchies'][i]);
  }

  for (let ft of fit_type_list) {
    console.log(sqr.value);
    console.log(checkedPerturbations.value);
    global_store.setLoading(true);

    await api
      .post(import.meta.env.VITE_API_URL + 'underwriting/curve-fit', {
        bounce_id: portfolio_store.selectedBounceID,
        filters_hierarchy: underwriting_store.convertedFilter,
        report_date: dashboard_store.report_date,
        fit_type: ft,
        perturbations: checkedPerturbations.value,
        hierarchies_order: hierarchies_order.slice(0, underwriting_store.currentLevel),
        sqr: sqr.value,
        num_of_rates: rates.value,
        levels_fit: levels_fit.value,
        credit_fit: credit_fit.value,
        pert_fit: pert_fit.value,
      })
      .then((res) => {
        let old_shift = structuredClone(curveShift.value);
        levels_fit.value = res.data.data['starting_points'];
        credit_fit.value = res.data.data['credits'];
        pert_fit.value = res.data.data['perts'];

        console.log(sqr.value);
        console.log(checkedPerturbations.value);
        console.log(portfolio_store.all_uw_dates['month']);

        if (ft == fit_type_list[fit_type_list.length - 1]) {
          att_apriori_from_fit.value = res.data.data['att_apriori'];
          is_original.value = false;
          fitting_default_run(old_shift);
        }
        // updateForwardlooking()

        global_store.setLoading(false);
        fit_status.value = 1;
      })
      .catch((e) => {
        console.log(e);
        global_store.setLoading(false);
        fit_status.value = 1;
        openErrorMsg(toTitleCase(ft) + " fitting couldn't be completed");
      });
  }
}
</script>
<template>
  <div class="absolute top-20 right-0 z-50" style="margin-top: -15px">
    <div
      :style="{
        width: filtersWidth,
        transition: '0.5s ease-out all',
      }"
    >
      <ChartFilter
        style="width: inherit"
        @clickFilters="fetchData()"
        @resizeFilter="resizeFilter"
        :filtersWidth="filtersWidth"
      />
    </div>
  </div>
  <div class="relative" :style="{ width: 'calc(100% - ' + filtersWidth + ')', transition: '0.5s ease-out all' }">
    <div
      class="fixed bg-white shadow-md z-50 overflow-y-scroll"
      style="width: 300px"
      :style="{
        height: windowHeight + 125 + 'px',
        right: 'calc( ' + filtersWidth + ')',
        top: '65px',
        transition: '0.5s ease-out all',
      }"
    >
      <div class="flex flex-row w-full h-12 bg-white sticky top-0 px-3 pt-1 z-50">
        <div v-if="fit_status == 0" class="relative w-3/4">
          Fitting in Progress
          <el-progress :percentage="100" status="warning" :indeterminate="true" :duration="1" class="w-full" />
        </div>
        <el-button v-if="fit_status == 0" class="absolute right-2 top-2" type="danger" @click="">Cancel</el-button>
        <div v-if="fit_status == 1 && (!is_original || rateChange != 0) && false" class="relative w-full">
          <div class="h-12 text-xl relative text-red-500 p-1 w-68 hover:scale-105 ease-in-out duration-1000">
            <div class="absolute left-0 flex flex-row">
              <h1 class="-rotate-90 mt-1 -ml-2 scale-x-90">FWD</h1>
              <h1 class="-rotate-90 mt-1 -ml-6 scale-x-90">{{ dashboard_store.dashboards.ccr_nlr }}</h1>
            </div>
            <div class="grid grid-cols-3 gap-1 z-20 watermark absolute pl-10 pr-2 mt-1 w-full">
              <el-tooltip placement="bottom" content="Current month forward looking">
                <p class="text-lg cursor-help text-center -mt-3 text-red-500 hover:text-sybil-teal">
                  {{ (forwardlooking[0] * 100).toFixed(1) }}%
                </p>
              </el-tooltip>
              <el-tooltip placement="bottom" content="Average next 12 months forward looking">
                <b class="text-xl cursor-help font-bold mr-1 text-center text-red-700 hover:text-sybil-teal"
                  >{{ (forwardlooking[1] * 100).toFixed(1) }}%
                </b>
              </el-tooltip>
              <el-tooltip placement="bottom" content="After 12 months forward looking">
                <p class="text-lg mt-4 cursor-help text-center text-red-500 hover:text-sybil-teal">
                  {{ (forwardlooking[2] * 100).toFixed(1) }}%
                </p>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="CurveShiftExpand = !CurveShiftExpand"
          ><CaretRight v-if="!CurveShiftExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="CurveShiftExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="CurveShiftExpand = !CurveShiftExpand"
          >Curve Shift</span
        >
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="CurveShiftExpand" class="chunk-group-elements">
          <div class="w-full px-2 pt-5 mb-4">
            <div class="flex flex-row">
              <el-button
                class="w-10 scale-150 ml-3"
                style="height: 40px"
                type="info"
                :disabled="rateChange == 0"
                plain
                @click="resetCurveShift()"
                ><el-icon class=""><Close /></el-icon
              ></el-button>

              <el-input-number
                v-model="curveShift"
                :precision="1"
                :step="0.1"
                :value-on-clear="0"
                size="large"
                style="width: 138px"
                controls-position="right"
                class="scale-150 ml-11"
                @change="changeCurveShift"
              />
            </div>
          </div>
        </div>
      </transition>

      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="PeriodRangeExpand = !PeriodRangeExpand"
          ><CaretRight v-if="!PeriodRangeExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="PeriodRangeExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="PeriodRangeExpand = !PeriodRangeExpand"
          >SQWELR Period Range</span
        >
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="PeriodRangeExpand" class="chunk-group-elements px-2">
          <div class="flex flex-row">
            <div class="mr-2">
              <Calendar
                v-model="calendarFrom"
                :minDate="moment(portfolio_store.all_uw_dates['month'][0], 'MMM-YYYY').toDate()"
                :maxDate="moment(calendarTo).subtract(1, 'months').toDate()"
                view="month"
                dateFormat="M-yy"
                class="h-8"
                v-on:date-select="periodChange()"
                :manualInput="false"
              />
            </div>
            <div class="mr-2 mt-1">To</div>
            <div>
              <Calendar
                v-model="calendarTo"
                :minDate="moment(calendarFrom).add(1, 'months').toDate()"
                :maxDate="moment(dashboard_store.report_date, 'YYYY-MM-DD').toDate()"
                view="month"
                dateFormat="M-yy"
                class="h-8"
                v-on:date-select="periodChange()"
                :manualInput="false"
              />
            </div>
          </div>
        </div>
      </transition>
      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="PasimonyExpand = !PasimonyExpand"
          ><CaretRight v-if="!PasimonyExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="PasimonyExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="PasimonyExpand = !PasimonyExpand"
          >Diagnostics</span
        >
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="PasimonyExpand" class="chunk-group-elements px-2 w-full text-sm mt-3">
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-4">SQWELR (orig.)</div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ sqwelr.toFixed(2) }}%
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-4">SQWELR (new)</div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ sqwelrNew.toFixed(2) }}%
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-4"># Data Points</div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ data_points }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-1">
              # Level<br />Parameters
            </div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ levels }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-1">
              # Rate Credit<br />Parameters
            </div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ rates }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l w-1/2 h-12 text-center pt-4"># Perturbations</div>
            <div class="border-gray-200 border-t border-l border-r w-1/2 h-12 text-center pt-4 bg-white">
              {{ checkedPerturbations.length }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="border-gray-300 bg-gray-100 border-t border-l border-b w-1/2 h-12 text-center pt-1">
              # Data Points per Parameter
            </div>
            <div
              class="border-gray-200 border w-1/2 h-12 text-center pt-1 text-white font-bold text-3xl"
              :class="
                data_points / (levels + rates + checkedPerturbations.length) <= 5
                  ? 'bg-red-500'
                  : data_points / (levels + rates + checkedPerturbations.length) <= 10
                  ? 'bg-orange-500'
                  : 'bg-sybil-teal'
              "
            >
              {{ (data_points / (levels + rates + checkedPerturbations.length)).toFixed(0) }}
            </div>
          </div>
        </div>
      </transition>
      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="PerturbationsExpand = !PerturbationsExpand"
          ><CaretRight v-if="!PerturbationsExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="PerturbationsExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="PerturbationsExpand = !PerturbationsExpand"
          >Perturbations Locator</span
        >
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="PerturbationsExpand" class="chunk-group-elements px-2 w-full text-sm mt-3">
          <div class="flex flex-row">
            <div class="mr-2 mt-1">Sensitivity</div>
            <div class="w-12 mr-2">
              <el-input-number
                v-model="perturbations_sensitivity"
                :precision="0"
                :step="1"
                :min="1"
                :max="100"
                controls-position="right"
                name="ddd"
                :value-on-clear="0.01"
                style="width: 110px"
              />
            </div>
            <div class="w-12 ml-16">
              <el-button type="primary" @click="PertLocate()">Locate</el-button>
            </div>
          </div>

          <el-checkbox
            v-model="checkAllPerturbations"
            :indeterminate="isIndeterminatePerturbations"
            @change="handleCheckAllPerturbationsChange"
            >Check/Uncheck All</el-checkbox
          >
          <el-checkbox-group
            v-model="checkedPerturbations"
            @change="handleCheckedPerturbationsChange"
            class="h-96 overflow-y-scroll border px-5 mx-10"
          >
            <template
              v-for="datapointUW of underwriting_chart_data.slice(
                0,
                underwriting_chart_data.map((x) => x['uw_data.UNDERWRITING_MONTH']).indexOf(asAtDate) + 1
              )"
              :key="datapointUW['uw_data.UNDERWRITING_MONTH']"
            >
              <el-checkbox :label="datapointUW['uw_data.UNDERWRITING_MONTH']">{{
                datapointUW['uw_data.UNDERWRITING_MONTH']
              }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </transition>

      <div class="side-panel-title ease-in-out duration-1000">
        <el-icon class="pt-3 cursor-pointer ml-1" @click="CurveFittingExpand = !CurveFittingExpand"
          ><CaretRight v-if="!CurveFittingExpand" class="text-gray-400 text-sm" /><CaretBottom
            v-if="CurveFittingExpand"
            class="text-gray-400 text-sm"
        /></el-icon>
        <span
          class="text-sybil-charcoal ease-in-out duration-1000 -mt-2 ml-1 cursor-pointer"
          @click="CurveFittingExpand = !CurveFittingExpand"
          >Curve Fitting</span
        >
      </div>
      <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div v-if="CurveFittingExpand" class="chunk-group-elements px-2 mb-10 w-full text-sm mt-3">
          <div class="flex my-3 w-full">
            <el-button plain class="w-40 mr-5" type="primary" :disabled="fit_status == 0" @click="fitData('all')"
              >All variables</el-button
            >
            <br />
            <el-button plain class="w-40" type="primary" :disabled="fit_status == 0" @click="fitData('level')"
              >Level only</el-button
            >
          </div>
          <div class="flex my-3 w-full">
            <el-button
              plain
              class="w-40 mr-5"
              type="primary"
              :disabled="fit_status == 0 || rates == 0"
              @click="fitData('internal')"
              >Internal</el-button
            >
            <br />
            <el-button
              plain
              class="w-40"
              type="primary"
              :disabled="fit_status == 0 || checkedPerturbations.length == 0"
              @click="fitData('perturbations')"
              >Perturbations</el-button
            >
          </div>
          <div class="flex w-full">
            <el-button
              class="w-40 mr-2"
              plain
              type="danger"
              :disabled="(is_original || fit_status == 0) && rateChange == 0"
              @click="resetFit()"
              >Reset</el-button
            >
            <el-button
              class="w-40"
              type="primary"
              :disabled="(is_original || fit_status == 0) && rateChange == 0"
              @click="confirmFitData()"
              >Confirm
            </el-button>
          </div>
        </div>
      </transition>
    </div>

    <div
      class="flex relative sticky top-20 bg-white justify-center h-10 shadow-md p-2"
      :style="{ width: 'calc(100% - 300px )', transition: '0.5s ease-out all' }"
    >
      <div class="h-6 w-6 mr-2" style="background-color: #b5e8d5"></div>
      <div>Unearned GWP</div>
      <div class="h-6 w-6 mr-2 ml-4" style="background-color: #55b691"></div>
      <div>GEP</div>
      <div class="h-6 w-6 mr-2 ml-4">
        <div class="h-1 align-middle w-6" style="background-color: #eb4034; margin-top: 10px"></div>
      </div>
      <div>Attritional A-priori</div>
      <div class="h-6 w-6 mr-2 ml-4 flex items-center">
        <div class="h-1 w-1" style="background-color: #030bfc"></div>
        <div class="h-4 w-4 border-1 rounded-full" style="background-color: #030bfc">
          <div class="h-2 w-2 mt-1 ml-1 border-1 rounded-full" style="background-color: #ffffff"></div>
        </div>
        <div class="h-1 w-1" style="background-color: #030bfc"></div>
      </div>
      <div>Seasonality Adjusted Attritional LR</div>
      <div
        class="absolute right-3 top-3 hover:text-sybil-teal cursor-pointer"
        @click="chartHeightDivider = 3.02 - chartHeightDivider"
      >
        <el-icon><Expand /></el-icon>
      </div>
    </div>
    <div
      class="bg-white shadow-md pl-2 pt-1 sidebarTextSize sticky top-32 pb-4 mt-1"
      :style="{
        height: windowHeight / chartHeightDivider + 'px',
        width: 'calc(100% - 300px )',
        transition: '0.5s ease-out all',
      }"
    >
      Actual vs A-priori by Underwriting Month
      <div class="flex flex-row">
        <vue-slider
          v-model="sliderValue"
          :process="false"
          :tooltip-formatter="onChangeVueSlider"
          :tooltip-style="{ background: '#ff0000' }"
          :dot-style="{ boxShadow: '0 0 2px 2px red' }"
          class="mt-3"
          :tooltip="'active'"
          :interval="0.1"
          :tooltip-placement="'right'"
          direction="btt"
          :style="{ height: windowHeight / chartHeightDivider - 150 + 'px' }"
          :processFunc="processFunc"
          :duration="0"
        ></vue-slider>
        <div
          id="underwritingDiv"
          style="width: 100%; background-color: white"
          :style="{ height: windowHeight / chartHeightDivider - 40 + 'px' }"
        ></div>
      </div>
    </div>
    <div
      class="bg-white shadow-md pl-2 pt-1 sidebarTextSize sticky top-32 pb-4 mt-3"
      :style="{
        height: windowHeight / chartHeightDivider + 40 + 'px',
        width: 'calc(100% - 300px )',
        transition: '0.5s ease-out all',
      }"
    >
      Actual vs A-priori by Accident Month
      <div class="flex flex-row">
        <vue-slider
          v-model="sliderValue"
          :process="false"
          :tooltip-formatter="onChangeVueSlider"
          :tooltip-style="{ background: '#ff0000' }"
          :dot-style="{ boxShadow: '0 0 2px 2px red' }"
          class="mt-3"
          :tooltip="'active'"
          :interval="0.1"
          :tooltip-placement="'right'"
          direction="btt"
          :style="{ height: windowHeight / chartHeightDivider - 150 + 'px' }"
          :processFunc="processFunc"
          :duration="0"
        ></vue-slider>
        <div
          id="accidentDiv"
          style="width: 100%; background-color: white"
          :style="{ height: windowHeight / chartHeightDivider + 'px' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.side-panel-title {
  height: 30px;

  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));

  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  position: sticky;
  top: 3rem;
  font-size: 18px;
  z-index: 10;
}

.chunk-group-elements {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
</style>
